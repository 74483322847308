/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    background-color: #f8f9fc;
}

.mat-header-cell,
.mat-cell {
    vertical-align: middle !important;
    padding: 0px 14px !important;
}

.page-header-sm {
    display: flex;
}

.h3-sm {
    font-size: 1.5rem !important;
}

.scroll-to-top {
    z-index: 1;
}

.sidebar {
    position: fixed;
    transition: width 0.15s ease;
}

.sidebar .sidebar-brand .sidebar-brand-text {
    display: inline !important;
}

label {
    font-weight: 500;
}

.custom-badge {
    border-radius: 50% !important;
    width: 20px !important;
}

.d-mobile {
    display: none;
}

.sidebar hr.sidebar-divider {
    margin: 0px 0rem 0rem !important;
}

.custom-badge-md {
    width: 30px !important;
    height: 30px !important;
    text-align: center;
    padding: 7px;
    font-size: 16px;
}

.footer-logo {
    height: auto;
    width: 13%;
}

@media (max-width: 576px) {
    .btn-lg {
        font-size: 1rem !important;
    }

    .form-control-lg {
        font-size: 1rem !important;
    }

    .input-group-lg > .form-control {
        font-size: 1rem !important;
    }

    .input-group-lg > .input-group-append > .btn {
        font-size: 1rem !important;
    }

    .scroll-to-top {
        /* bottom: 4rem !important; */
        display: none !important;
    }

    .footer {
        position: fixed;
        left: 0;
        bottom: 0;
        width: -webkit-fill-available;
        background-color: rgba(255, 255, 255, 1);
        /* color: white; */
        text-align: center;
        padding: 10px 0px;
        z-index: 2;
    }

    .footer-paginator {
        position: fixed;
        left: 0;
        bottom: 0;
        width: -webkit-fill-available;
        background-color: rgba(255, 255, 255, 1);
        /* color: white; */
        text-align: center;
        z-index: 2;
    }

    .sidebar {
        width: 16rem;
        min-height: 100vh;
        z-index: 4;
        position: fixed;
    }

    .nav-link.admin {
        display: flex;
        height: 3rem;
        width: 100% !important;
    }

    .nav-link > i {
        margin-left: 12px;
        margin-top: 3px;
    }

    .nav-link > span {
        margin-left: 20px;
        margin-top: 2px;
        font-size: 16px !important;
    }

    .sidebar.toggled {
        transition: width 0.15s ease;
    }

    .static-top {
        position: fixed !important;
        top: 0;
        z-index: 3;
        width: 100%;
    }

    #content {
        margin-top: 86px;
    }

    .sidebar-brand-sm {
        font-size: 22px;
        margin-left: -20px;
        margin-top: -2px;
    }

    .btn-mobile {
        font-size: 0.75rem !important;
        margin-right: 24px !important;
    }

    .btn-mobile-sm {
        font-size: 0.75rem !important;
    }

    .container-fluid {
        padding-left: 0.75rem !important;
        padding-right: 0.75rem !important;
    }

    .mobile-shadow {
        box-shadow: 0 0.15rem 1.75rem 0 rgb(58 59 69 / 15%) !important;
    }

    #navbarCollapse {
        border-top: 1px solid rgba(0, 0, 0, 0.07);
        margin-top: 20px;
    }

    .d-mobile {
        display: flex;
    }

    .d-mobile-none {
        display: none !important;
    }

    .mat-date-range-input {
        padding: 0.375rem 0.375rem !important;
    }

    .col-sm-6 {
        flex: 0 0 50% !important;
        max-width: 50% !important;
    }

    .card-col-mob-full {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }

    /* .mat-tab-label-container {
        display: flex;
        flex-grow: 1;
        overflow: hidden;
        z-index: 1;
        overflow-x: scroll !important;
    } */

    /* .mat-tab-header {
        overflow-x: scroll !important;
    } */

    .mat-tab-label-container {
        overflow: auto !important;
    }

    .mat-tab-label-container::-webkit-scrollbar {
        display: none !important;
    }

    .mat-tab-header-pagination {
        display: none !important;
    }

    .mat-table-sticky:first-child {
        left: -1px !important;
        background-color: rgb(14, 164, 225);
        color: #fff;
    }

    .mob-parent-card {
        border: none !important;
        box-shadow: none !important;
    }

    .mob-parent-cardheader:first-child {
        border-radius: calc(0.35rem - 1px) !important;
    }

    .mat-paginator-range-label {
        margin: 0 20px 0 20px !important;
    }

    .card-footer {
        border-top: none !important;
    }

    .mat-autocomplete-panel {
        min-width: 305px !important;
    }

    .mat-menu-panel {
        max-width: 314px !important;
    }

    .mobile-ellipsis-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 140px;
    }

    .footer-logo {
        height: auto;
        width: 43%;
    }
}

@media (min-width: 768px) {
    .sidebar {
        position: fixed;
        width: 14rem !important;
        top: 0 !important;
        z-index: 4;
        left: 0px;
    }

    #wrapper #content-wrapper {
        margin-left: 14rem;
    }

    .sidebar.toggled + #content-wrapper {
        margin-left: 0rem !important;
    }

    .sidebar.toggled .nav-link.admin {
        display: none !important;
    }

    .static-top {
        position: fixed !important;
        top: 0;
        z-index: 4;
        width: calc(100% - 14rem);
    }

    .sidebar.toggled + #content-wrapper > .static-top {
        width: calc(100%);
    }

    #content {
        margin-top: 86px;
    }

    .sidebar-toggle-btn {
        position: fixed;
        bottom: 2rem;
        left: 5.5rem;
    }

    .sidebar.toggled > .sidebar-toggle-btn {
        position: fixed;
        bottom: 2rem;
        left: 2rem;
    }

    ::ng-deep .custom-menu + .cdk-verlay-connected-position-bounding-box {
        display: none !important;
    }

    mat-date-range-input {
        max-width: 230px !important;
    }
}

@media (max-width: 1024px) {
    .d-mobile {
        display: flex;
    }

    .d-mobile-none {
        display: none !important;
    }

    .mat-table-sticky:first-child {
        left: -1px !important;
        background-color: rgb(14, 164, 225);
        color: #fff;
    }

    .sidebar.toggled .nav-link.admin {
        display: none !important;
    }
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: rgba(14, 164, 225, 1) !important;
}

.mat-tab-label .mat-tab-label-content {
    font-size: 18px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
